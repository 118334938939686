import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Row } from "react-bootstrap"
import React from "react"
import styled from "styled-components"

import MainMenu from "./MainMenu"
const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
`

const StyledHeader = styled.header`
  .loading {
    height: 100vh;
  }
  background: #4a5054;
`
function Header() {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allWordpressPage(filter: { path: { eq: "/" } }) {
        edges {
          node {
            title
            path
            template
            acf {
              header_logo {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  console.log(data)
  return (
    <StyledHeader className={!data ? "loading" : ""}>
      <Container>
        <Row>
          <LogoImage
            src={data.allWordpressPage.edges[0].node.acf.header_logo.source_url}
            alt="logo"
          />
        </Row>
      </Container>
    </StyledHeader>
  )
}
export default Header
// export default () => (
//   <StaticQuery
//     query={graphql`
//       {
//         allWordpressPage(filter: { path: { eq: "/" } }) {
//           edges {
//             node {
//               title
//               path
//               template
//               acf {
//                 header_logo {
//                   source_url
//                 }
//               }
//             }
//           }
//         }
//       }
//     `}
//     render={data => (

//       <Header>
//         <div className="container">
//           <div className="row">
//             <LogoImage
//               src={
//                 data.allWordpressPage.edges[0].node.acf.header_logo.source_url
//               }
//               alt="logo"
//             />
//             {/* <MainMenu /> */}
//           </div>
//         </div>
//       </Header>
//     )}
//   />
// )
