import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"
const MainMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const MenuItem = styled(Link)`
  display: block;
  padding: 8px 16px;
  color: #000;
  font-weight: 400;
  font-size: 1.4rem;
  &.active {
    color: #b88746;
  }
  &:hover {
    color: gold;
    text-decoration: none;
  }
  text-decoration: none;
`
const MainMenu = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(
            filter: { name: { eq: "Main Nav" } }
          ) {
            edges {
              node {
                items {
                  title
                  object_slug
                }
              }
            }
          }
        }
      `}
      render={props => (
        <MainMenuWrapper>
          {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
            item => (
              <MenuItem
                to={item.object_slug === "home" ? "/" : `/${item.object_slug}`}
                activeClassName="active"
                key={item.title}
              >
                {item.title}
              </MenuItem>
            )
          )}
        </MainMenuWrapper>
      )}
    />
  )
}

export default MainMenu
