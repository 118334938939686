/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./header"
import { useStaticQuery } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
  body,html{
    
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;

  }
`

const LayoutWrapper = styled.div`
  margin: 0 auto;
  background: #00091b;
  color: #fff;
`

export default ({ children, location }) => (
  <div>
    <GlobalStyles />
    <Header />

    <LayoutWrapper>{children}</LayoutWrapper>
  </div>
)
